<template>
    <div id="sips" class="sips">
        <Steps :steps="processSteps" :nbrProducts="nbrProducts" :active="2" />
        <div v-if="enablePaymentMethod()">
            <div class="transaction-infos">
                <span class="store">{{store}}</span>
                <span class="total-amount">{{$t("sipsTransactionAmount", {totalAmount: totalAmount.toFixed(2)})}}</span>
            </div>
            <div class="credit-card-form">
                <span class="indications">{{$t("sipsFormTitle")}}</span>
                <span v-if="showError" class="indications error">{{$t("sipsError")}}</span>
                <div class="divider"></div>
                <div :class="{'input': true, 'error': !inputValidations.cardNumber}">
                    <label for="cardnumber">{{$t("sipsCreditCardLabel")}}</label>
                    <input type="tel" id="card-number" placeholder="4200 #### #### ####" name="cardnumber" :class="{'error-outline': !inputValidations.cardNumber}" v-model="cardNumber" v-mask="'#### #### #### ####'"/>
                </div>
                <div class="divider"></div>
                <div :class="{'input': true, 'error': !inputValidations.expirationYear || !inputValidations.expirationMonth}">
                    <label for="card-exp-date">{{$t("sipsDateLabel")}}</label>
                    <div class="card-exp-date">
                        <div :class="{'card-exp-month with-indication': true, 'error': !inputValidations.expirationMonth}">
                            <label for="card-exp-month" class="start">{{$t("sipsMonthLabel")}}</label>
                            <input name="month" type="tel" id="card-exp-month" v-mask="'##'" :class="{'error-outline': !inputValidations.expirationMonth}" placeholder="12" v-model="expirationMonth" />
                            <span class="icon-question indication"></span>
                        </div>
                        <div :class="{'card-exp-year with-indication': true, 'error': !inputValidations.expirationYear}">
                            <label for="card-exp-month">{{$t("sipsYearLabel")}}</label>
                            <input ref="expiryYear" name="year" type="tel" id="card-exp-year" v-mask="'####'" :placeholder="currentDate.getFullYear()" :class="{'error-outline': !inputValidations.expirationYear}" v-model="expirationYear" />
                            <span class="icon-question indication"></span>
                        </div>
                    </div>
                    
                </div>
                <div class="divider"></div>
                <div :class="{'input': true, 'error': !inputValidations.cvv}">
                    <label for="card-cvv">{{$t("sipsCVVLabel")}}</label>
                    <div :class="{'with-indication': true, 'error': !inputValidations.cvv}">
                        <input type="tel" id="card-cvv" name="cvv2" v-mask="'###'" placeholder="123" v-model="cvv" :class="{'error-outline': !inputValidations.cvv}"/>
                        <span class="icon-question indication"></span>
                    </div>
                </div>
                <div class="actions-wrapper">
                    <b-btn class="custom-btn outlined" @click="$router.push(base + '/paymentMethods')">{{$t("cancel")}}</b-btn>
                    <b-btn class="custom-btn" @click="sendTransaction()">{{$t("validate")}}</b-btn>
                </div>
            </div>
            <div class="payment-footer">
                <span class="text">{{$t("sipsFooterExplain")}}</span>
                <div class="payment-logos">
                    <div class="logo" :style="{backgroundImage: `url(${require('@/assets/img/cb.jpg')})`}"></div>
                    <div class="logo" :style="{backgroundImage: `url(${require('@/assets/img/verified_visa.png')})`}"></div>
                    <div class="logo" :style="{backgroundImage: `url(${require('@/assets/img/mastercard.png')})`}"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {isNotNull, getSteps} from "@/assets/js/Utils"
    import Steps from "@/components/Food/Steps";
    var checkout = require("@/assets/js/Checkout");
    export default {
        name: "Sips",
        components: {
            Steps
        },
        data: function() {
            return {
                cardNumber: "",
                expirationYear : "",
                expirationMonth : "",
                cvv: "",
                showLoader : true,
                totalAmount : 0,
                collapseTransactionInfos: true,
                loaderContent : "You will be redirected to the payment page...",
                showContent : false,
                errorMessage : "",
                processSteps: getSteps(),
                inputValidations:{
                    cardNumber: true,
                    expirationYear : true,
                    expirationMonth : true,
                    cvv: true,
                },
                base: localStorage.getItem("DKC_base"),
                store: localStorage.getItem("DKC_food_brand")+ " " + localStorage.getItem("DKC_food_city"),
                currentDate : new Date()
            }
        },
        created(){
            this.totalAmount = this.$parent.basket.totalAmount
        },
        methods : {
            getContext : function() {
                var contextId = this.merchantId+Date.now();
                return contextId;
            },
            sendTransaction: function() {
                if(this.validInputs()){
                    this.$router.push(this.base + '/paymentdone')
                }
            },
            enablePaymentMethod() {
                return checkout.enablePaymentMethod(this.$options.name, this);
            }, 
            validInputs(){
                this.inputValidations.cardNumber = this.cardNumber !== "" && this.cardNumber.length == 19
                
                this.inputValidations.expirationYear = this.expirationYear !== "" && this.expirationYear.length == 4 && parseInt(this.expirationYear) > this.currentDate.getFullYear()

                this.inputValidations.expirationMonth = this.expirationMonth !== "" && this.expirationMonth.length == 2 && parseInt(this.expirationMonth) > 0 && parseInt(this.expirationMonth) <= 12

                this.inputValidations.cvv = this.cvv !== "" && this.cvv.length == 3

                return Object.values(this.inputValidations).reduce((acc, value) => acc && value, true)
            }
        },
        computed:{
            showError(){
                return !Object.values(this.inputValidations).reduce((acc, value) => acc && value, true)
            },
            nbrProducts(){
                return this.$parent.basket.products.reduce((acc, product) => {return acc + product.qty}, 0)
            }
        },
        watch:{
            expirationMonth(newValue){
                if(newValue.length == 2){
                    this.$refs.expiryYear.focus()
                }
            }
        }
    }
</script>